.stats-container {
    margin: 40px 0;
    background: linear-gradient(245deg, #093053 34.1%, #021526 100.02%);
    padding: 50px;

    .stats-head {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 15px;

        h1 {
            font-size: 32px;
            color: #fff;
            font-weight: 600;
            text-align: center;
        }

        p {
            font-size: 18px;
            text-align: center;
            color: #fff;
        }
    }

    .stats {
        margin: 40px 0;
        display: grid;
        gap: 20px;

        // Default: 4-Column Layout
        &.columns-4 {
            grid-template-columns: repeat(4, 1fr);
        }

        // 6-Column Layout
        &.columns-6 {
            grid-template-columns: repeat(6, 1fr);
        }

        .stats-card {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            padding: 30px;
            gap: 10px;
            min-width: 140px;
            border-right: 1px solid rgba(180, 180, 180, 0.375); // ✅ Border-right added

            span {
                font-size: 80px;
                color: #fff;
            }

            p {
                color: #fff;
                text-align: center;
                font-size: 16px;
            }
        }

        /* ✅ Remove right border for last item in each row */
        &.columns-4 .stats-card:nth-child(4n),
        &.columns-6 .stats-card:nth-child(6n),
        &.columns-2 .stats-card:nth-child(2n),
        &.columns-1 .stats-card {
            border-right: none;
        }
    }
}

/* 📱 Responsive Layouts */
@media (max-width: 1024px) {
    .stats-container {
        .stats {
            &.columns-4 {
                grid-template-columns: repeat(2, 1fr) !important;  // 2 columns on tablets
            }
            &.columns-6 {
                grid-template-columns: repeat(3, 1fr) !important;  // 3 columns on tablets
            }
        }
        &.columns-4 .stats-card:nth-child(2n),
        &.columns-6 .stats-card:nth-child(3n),
        // &.columns-2 .stats-card:nth-child(2n),
        &.columns-1 .stats-card {
            border-right: none;
        }
    }
}

@media (max-width: 768px) {
    .stats-container {
        .stats {
            &.columns-4,
            &.columns-6 {
                grid-template-columns: repeat(2, 1fr) !important; // 2 columns for small screens
            }

            .stats-card {
                padding: 20px;

                span {
                    font-size: 50px;
                }
            }
        }
           &.columns-4 .stats-card:nth-child(2n),
        &.columns-6 .stats-card:nth-child(2n),
        // &.columns-2 .stats-card:nth-child(2n),
        &.columns-1 .stats-card {
            border-right: none;
        }
    }
}

@media (max-width: 480px) {
    .stats-container {
        padding: 30px;

        .stats {
            &.columns-4,
            &.columns-6 {
                grid-template-columns: 1fr !important; // 1 column for mobile
            }

            .stats-card {
                border-right: none; // ✅ No border on mobile
                span {
                    font-size: 40px;
                }

                p {
                    font-size: 14px;
                }
            }
        }
     
    }
}
