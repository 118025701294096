.feature-list-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    padding: 20px;
    max-width: 1080px;
    margin: 0 auto;
    margin-top: 100px;

    .feature-title {
        text-align: center;
        font-size: 32px;
        font-weight: 600;
    }
    .feature-sub-title {
        font-size: 18px;
        text-align: center;
        line-height: 22px;
    }
    .feature-list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 30px;

        .feature-card {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 15px;
            transition: all 0.2s ease-in-out;
            cursor: auto;
            border-radius: 10px;
            .feature-icon {
                border-radius: 10px;
                background: var(--Base-White, #fff);
                height: 48px;
                width: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
            }

            .feature-card-title {
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                margin: 20px 0px 8px 0px;
                line-height: 24px;
            }

            .feature-card-sub-title {
                color: #475467;
                font-weight: 400;
                line-height: 24px;
            }

            &:hover {
                transform: translateY(-5px);
                box-shadow: rgba(120, 120, 218, 0.362) 0px 0px 16px;
                background-color: #ffffff;
            }
        }
    }
}

@media (max-width: 480px) {
    .feature-list-wrapper {
        .feature-title {
            font-size: 24px;
        }
        .feature-sub-title {
            font-size: 16px;
        }
        .feature-list {
            grid-template-columns: 1fr;

            .feature-card {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 10px;

                .feature-icon {
                    border-radius: 10px;
                    background: var(--Base-White, #fff);
                    height: 48px;
                    width: 48px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                }

                .feature-card-title {
                    font-weight: 600;
                    text-align: center;
                }

                .feature-card-sub-title {
                    text-align: center;
                    color: #475467;
                    font-weight: 400;
                }
            }
        }
    }
}
